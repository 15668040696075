.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  margin: -10px;
}

.App-header {
  margin: 0;
}

.App-name {
  font-size: 1.8rem;
  font-weight: 900;
}

.App-title {
  color: #f2f2f2;
  margin: 15px 10px;
  font-size: 175%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden; 
  /* display:inline; */
}

.App-container {
  margin: 0 auto;
}

.App-message-box {
  color: #9CAFA3;
  font-size: 0.875rem;
}
